


























































































































import Vue from "vue";
import { MatchingCandidates } from "@/interfaces/recruiter/dashboard/matching_candidates";
import { mapActions, mapGetters, mapMutations } from "vuex";

import SingleCandidate from "@/components/recruiter/dashboard/SingleCandidate.vue";
import {
  JOBS_POSTED_BY_RECRUITER,
  JOBS_POSTED_BY_RECRUITER_LOADING,
  MATCHING_CANDIDATES,
  TOP_JOBS_WITH_CANDIDATES,
  VIEW_CANDIDATES_DYNAMICALLY
} from "@/store/modules/recruiter/constants";

import DataNotFound from "@/components/shared/DataNotFound.vue";
import { generate_random_key } from "@/utils/global";
import router from "@/router";
import { Jobs } from "@/interfaces/data_objects/jobs";
import { JobsWithCandidates } from "@/store/modules/recruiter/interfaces";

export default Vue.extend({
  name: "MatchingCandidates",
  components: { SingleCandidate, DataNotFound },
  data(): MatchingCandidates {
    return {
      selected_job: null
    };
  },
  computed: {
    // Recruiter getters
    ...mapGetters("recruiter", {
      get_posted_jobs_loading: JOBS_POSTED_BY_RECRUITER_LOADING,
      top_posted_jobs: TOP_JOBS_WITH_CANDIDATES
    })
  },
  async mounted() {
    // If jobs fetched => select top job
    if (this.top_posted_jobs.results.length > 0) {
      this.selected_job = this.top_posted_jobs.results[0];
    }
    // If no jobs fetched => but jobs exist in DB
    // Fetch jobs & matching candidates
    else if (this.top_posted_jobs.total > 0) {
      await this.fetch_top_jobs_with_candidates({
        page: 0,
        limit_per_page: 5,
        dashboard: true
      });
      this.selected_job = this.top_posted_jobs.results[0];
    }
    const job_ids = this.top_posted_jobs.results
      .filter((job: JobsWithCandidates) => job.matching_candidates.length <= 0)
      .map((job: JobsWithCandidates) => job.jid);
    if (job_ids.length > 0)
      await this.get_matching_candidates({ job_ids, dashboard: true });
  },
  methods: {
    ...mapMutations("recruiter", {
      set_candidates_view_dynamically: VIEW_CANDIDATES_DYNAMICALLY
    }),
    getTitle() {
      return this.$t("recruiter.dashboard.matching-candidates-not-found");
    },
    generate_random_key,
    // Recruiter actions
    ...mapActions("recruiter", {
      get_matching_candidates: MATCHING_CANDIDATES,
      fetch_top_jobs_with_candidates: JOBS_POSTED_BY_RECRUITER
    }),
    /**
     * Function to navigate to view candidates page
     * @param job: candidates to display against job_id
     */
    async view_all_candidates(job: Jobs) {
      // sessionStorage.setItem("dynamic_job", job_id);
      this.set_candidates_view_dynamically({
        active: true,
        loading: true,
        job_id: job.jid,
        job_title: job.job_title
      });
      await router.push({
        path: `candidates`
      });
    }
  }
});
